import React from "react";
import Translation from "@common/Translation/Index";

const PagesetNote = ({
  product,
  itemtype,
  isPremium,
  isSynced,
  isSyncable,
}) => {
  const renderTranslation = (key) => (
    <i>
      <Translation translate={key} textonly="true" />
    </i>
  );

  let translationKey = "PSD_Note_Pageset";

  switch (product) {
    case "snapscene":
      translationKey = "PSD_Note_Backup_Snapscene";
      break;

    case "symbolmate":
      translationKey = "PSD_Note_Board";
      break;

    case "communicator":
      if (itemtype === "backups") {
        translationKey = "PSD_Note_Backup";
      }
      break;

    case "compass":
      translationKey = isPremium
        ? "PSD_Note_Premium_Pageset"
        : "PSD_Note_Pageset";
      break;

    case "snap":
      if (itemtype === "backups") {
        translationKey = "PSD_Note_Backup";
      } else if (itemtype === "pagesets") {
        translationKey = isSyncable
          ? "PSD_Note_Pageset_Snap_Syncable"
          : isSynced
            ? "PSD_Note_Pageset_Snap_Synced"
            : "PSD_Note_Pageset_Snap_Share";
      }
      break;

    default:
      break;
  }

  return renderTranslation(translationKey);
};

export default PagesetNote;
