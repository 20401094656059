import React from "react";
import Translation from "@common/Translation/Index";

const ProfileAvatar = ({
  profile,
  clearAvatarClick,
  handleFileInputChange,
  avatarFileValid,
}) => {
  const avatarSrc =
    avatarFileValid && profile.avatarUrl
      ? profile.avatarUrl
      : profile.avatarUrl
        ? `/api/v1/${profile.avatarUrl}`
        : "assets/images/avatar.jpg";
  return (
    <div className="row">
      <div className="col-sm-6 col-md-4 form-group">
        <label className="control-label" htmlFor="AvatarUrl">
          <Translation translate={"MYTD_avatarLbl"}></Translation>
        </label>{" "}
        <span className="optional">
          <Translation translate={"MYTD_optionalTxt"}></Translation>
        </span>
        <div className="fileinput-new" data-provides="fileinput">
          <div
            className="fileinput-preview thumbnail img-circle img-circle-51 center-block"
            style={{ lineHeight: "51px" }}
          >
            <img id="avatarImage" src={avatarSrc} alt="avatar" />
          </div>
          <div>
            <span className="btn btn-default btn-file">
              {!profile.avatarUrl && (
                <span>
                  <Translation translate={"MYTD_selectImgLbl"}></Translation>
                </span>
              )}
              {profile.avatarUrl && (
                <span>
                  <Translation translate={"MYTD_changeLbl"}></Translation>
                </span>
              )}
              <input
                type="file"
                name="addAvatar"
                id="addAvatar"
                accept="image/*"
                onChange={handleFileInputChange}
                data-testid="file-input"
                key={"addAvatar"}
              />
            </span>
            {profile.avatarUrl && (
              <a
                href="#"
                className="btn btn-default margin-sm-left"
                onClick={clearAvatarClick}
              >
                {<Translation translate={"MYTD_removeLbl"}></Translation>}
              </a>
            )}
          </div>
          <div
            className="field-validation-valid text-danger margin-top-sm"
            id="avError"
          ></div>
        </div>
      </div>
    </div>
  );
};
export default ProfileAvatar;
