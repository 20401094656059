import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Translation from "../../common/Translation/Index";
import Breadcrumb from "../../common/Breadcrumb";
import { useSelector } from "react-redux";
import SnapTrialHeader from "./SnapTrialHeader";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { getAppDownloadLinks, getSnapTrialsData } from "../../../services";
const TDSnapTrial = () => {
  const { languageCode } = useSelector((state) => state.languageData);
  const { translations } = useSelector((state) => state.translations);
  const { isAuthenticated } = useSelector((state) => state.user);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const product = params.get("product");

  const [snapTrialsData, setSnapTrialsData] = useState();
  const [appLinksData, setAppLinksData] = useState([]);

  useEffect(() => {
    const fetchSnapTrialsData = async () => {
      const data = await getSnapTrialsData(languageCode);
      setSnapTrialsData(data);
    };
    fetchSnapTrialsData();
  }, [languageCode]);

  useEffect(() => {
    const fetchAppLinksData = async () => {
      try {
        const data = await getAppDownloadLinks("snap-core-first", languageCode);
        setAppLinksData(data);
      } catch (error) {
        console.error("Error fetching app data:", error);
      }
    };
    fetchAppLinksData();
  }, [languageCode]);

  return (
    <>
      <div className="container-full">
        <div className="container myStuff-header">
          <h1>
            <Translation translate="Snap_Core_First" />
          </h1>
          <div>
            <Breadcrumb
              items={[
                { label: translations.Store, href: "/store" },
                {
                  label: translations.Snap_Core_First,
                  href: "/store/snap",
                },
                {
                  label: translations.Trial,
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className="container">
        {product === "PODD" && (
          <SnapTrialHeader
            translateTitle="Snap_with_PODD_Trial"
            translateDescription="Snap_with_PODD_Trial_Description"
            headerReplaceArray={["60"]}
          />
        )}
        {product === "Gateway" && (
          <SnapTrialHeader
            translateTitle="Snap_with_Gateway_Trial"
            translateDescription="Snap_with_Gateway_Trial_Description"
            headerReplaceArray={["60"]}
          />
        )}
        {product === "Speech" && (
          <SnapTrialHeader
            translateTitle="Snap_with_Speech_Trial"
            translateDescription="Snap_with_Speech_Trial_Description"
            headerReplaceArray={["60"]}
          />
        )}
        <div className="row" style={{ display: "flex", alignItems: "stretch" }}>
          <Step1
            isAuthenticated={isAuthenticated}
            snapTrialsData={snapTrialsData}
            product={product}
            trialIsActive="Trial_is_Active"
            trialHasStarted="Trial_has_started"
            daysRemaining="Days_remaining"
            trialHasExpired="Trial_has_Expired"
            trialHasEnded="Trial_has_ended"
            startTrial="Start_Trial"
            trialHasNotStarted="Trial_has_not_started"
            stepNumber="Step_Number"
            replaceArray={["1"]}
            activate="Activate"
          ></Step1>

          <Step2
            innerPageData={snapTrialsData}
            translations={translations}
            navigator={window.navigator}
            stepNumber="Step_Number"
            replaceArray={["2"]}
            download="Download"
            ifSnapIsInstalledGoToNextStep="If_snap_is_installed_go_to_next_step"
            appLinksData={appLinksData}
          ></Step2>

          <Step3
            stepNumber="Step_Number"
            replaceArray={["3"]}
            logIn="Log_in"
            logInToMyTDDuringAppStartUp="Log_in_to_myTobiiDynavox_during_app_start_up"
            followInstructions="Follow_the_instructions_in_the_app_to_begin_using_your_trial"
          ></Step3>
        </div>
      </div>
    </>
  );
};

export default TDSnapTrial;
