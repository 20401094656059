import React from "react";
import Modal from "react-bootstrap/Modal";
import Translation from "@common/Translation/Index";
import toast from "react-hot-toast";
import { deactivateDeviceLicense } from "@services";

const DeactivateModal = ({
  device,
  showModal,
  setShowModal,
  translations,
  fetchDevices,
}) => {
  const companionPermissions = {
    thisTitle: device.title,
    poddCompanionPermission:
      device.companionPermissions?.includes("com.tobiidynavox.snap.podd") ||
      false,
    gatewayCompanionPermission:
      device.companionPermissions?.includes("com.tobiidynavox.snap.gateway") ||
      false,
    speechPermission:
      device.companionPermissions?.includes("com.tobiidynavox.snap.speech") ||
      false,
  };

  const closeModal = () => setShowModal(false);

  const handleDeactivate = async () => {
    try {
      await deactivateDeviceLicense(device.serialNumber, device.licenseKey);
      await new Promise((resolve) => setTimeout(resolve, 200));
      toast.success(translations.Changes_saved);
      await fetchDevices();
      closeModal();
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error(translations.An_error_occurred_while_processing_your_request);
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      className={showModal ? "in" : ""}
    >
      <Modal.Header>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <Modal.Title>
          <h2>
            <Translation translate="MYTD_deactivateSpeechLbl" />
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Translation translate="MYTD_detachWarnMsg1" />
        </p>
        <table className="table devices SnapDeactivateModal">
          <tbody>
            {device.licenseKey && (
              <tr>
                <td>
                  <strong>
                    <Translation translate="MYTD_deactivateLicenseLbl" />
                  </strong>
                </td>
                <td>
                  <span>
                    <Translation translate="MYTD_detachWarnMsgLicense" />
                  </span>
                </td>
              </tr>
            )}

            {!!companionPermissions.speechPermission && (
              <tr>
                <td>
                  <strong>
                    <Translation translate="MYTD_deactivateSpeechLbl" />
                  </strong>
                </td>
                <td>
                  <span>
                    <Translation translate="MYTD_deactivateWarnMsg2" />
                  </span>
                </td>
              </tr>
            )}

            {!!companionPermissions.gatewayCompanionPermission && (
              <tr>
                <td>
                  <strong>
                    <Translation translate="Deactivate" /> Gateway
                  </strong>
                </td>
                <td>
                  <span>
                    <Translation
                      translate="Deactivate_permission"
                      textonly={true}
                      replaceArray={["Gateway"]}
                    />
                  </span>
                </td>
              </tr>
            )}

            {!!companionPermissions.poddCompanionPermission && (
              <tr>
                <td>
                  <strong>
                    <Translation translate="Deactivate" /> PODD
                  </strong>
                </td>
                <td>
                  <span>
                    <Translation
                      translate="Deactivate_permission"
                      textonly={true}
                      replaceArray={["PODD"]}
                    />
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <span>
          <button onClick={handleDeactivate} className="btn btn-lg btn-primary">
            <Translation translate="MYTD_deactivateLbl" />
          </button>
          <button onClick={closeModal} className="btn btn-lg btn-default">
            <Translation translate="MYTD_cancelBtnTxt" />
          </button>
        </span>
      </Modal.Footer>
    </Modal>
  );
};

export default DeactivateModal;
