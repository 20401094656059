import React from "react";
import { postAddMyProduct } from "@services/product-service";
import Translation from "@common/Translation/Index";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

const AddToMyStuffButton = ({ product }) => {
  const { translations } = useSelector((state) => state.translations);

  const { isAuthenticated } = useSelector((state) => state.user);
  const handleAddToMyStuff = async () => {
    try {
      await postAddMyProduct(product);
      toast.success(translations.Added);
    } catch (error) {
      toast.error(
        translations.An_error_occurred_while_processing_your_request,
        error,
      );
    }
  };

  return (
    isAuthenticated && (
      <a
        href="#"
        onClick={handleAddToMyStuff}
        data-product={product}
        className="btn btn-lg btn-default non-breadcrumb"
      >
        <Translation translate="MYTD_addToMyStuffBtnTxt" />
      </a>
    )
  );
};

export default AddToMyStuffButton;
