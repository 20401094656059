import React from "react";
import Translation from "../../common/Translation/Index";

const SnapTrialHeader = ({
  translateTitle,
  translateDescription,
  headerReplaceArray,
}) => {
  return (
    <>
      <h2>
        <Translation translate={translateTitle} />
      </h2>
      <p>
        <Translation
          translate={translateDescription}
          replaceArray={headerReplaceArray}
        />
      </p>
    </>
  );
};
export default SnapTrialHeader;
