import React, { useState } from "react";
import Translation from "@common/Translation/Index";
import EmailList from "@common/EmailList";
import { postShareCopy } from "@services";
import toast from "react-hot-toast";

const Share = ({ translations, type, product, id }) => {
  const [email, setEmail] = useState("");
  const [emailList, setEmailList] = useState([]);

  const handleShare = async () => {
    try {
      await postShareCopy({
        productName: product,
        pagesetId: id,
        usersEmailOrUsername: emailList,
        shareItemType: type,
      });
      toast.success(translations.Share_request_successfully_sent);
      setEmail("");
      setEmailList([]);
    } catch (error) {
      console.error("Error sharing pageset:", error);
      toast.error(translations.An_error_occurred_while_processing_your_request);
    }
  };

  return (
    <tr>
      <td colSpan="2">
        <hr />
        <div className="copy-form">
          <div className="margin-bottom-md">
            <h4>
              <Translation translate="MYTD_shareTxt" /> {type}{" "}
              <Translation translate="MYTD_copyText" />
            </h4>
            {type === "Pageset" ? (
              <Translation translate="MYTD_sharePageset" />
            ) : (
              <Translation translate="MYTD_shareBackup" />
            )}
          </div>
          <EmailList
            translations={translations}
            email={email}
            setEmail={setEmail}
            emailList={emailList}
            setEmailList={setEmailList}
          />
          <button
            disabled={emailList.length === 0}
            className="btn btn-lg btn-primary"
            onClick={() => handleShare()}
            data-testid="share-button"
          >
            <Translation translate="MYTD_shareBtnTxt" />
          </button>
          &nbsp;
          <button
            disabled={emailList.length === 0}
            className="btn btn-lg btn-default"
            data-testid="cancel-button"
            onClick={() => {
              setEmail("");
              setEmailList([]);
            }}
          >
            <Translation translate="MYTD_cancelBtnTxt" />
          </button>
        </div>
      </td>
    </tr>
  );
};

export default Share;
