import React, { useState, useEffect, useCallback } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";

import Pagination from "@common/PagesetCentral/PaginationControl";
import Translation from "@common/Translation/Index";
import { getBackups } from "@services";
import List from "./Pagesets/List";
import FilterInput from "./Pagesets/FilterInput";
import { filterBackups, paginateItems } from "@helpers/utilities";

const initialFilters = {
  text: "",
  page: 0,
};

const Backups = () => {
  const { product, translations } = useOutletContext();
  const { languageCode } = useSelector((state) => state.languageData);
  const [backups, setBackups] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState({
    itemOffset: 0,
    pageNumber: 0,
  });
  const [currentItems, setCurrentItems] = useState([]);
  const [total, setTotal] = useState(0);

  const fetchBackups = useCallback(async () => {
    const data = await getBackups(languageCode, product);
    setBackups(data);
  }, [languageCode, product]);

  useEffect(() => {
    fetchBackups();
  }, [fetchBackups]);

  useEffect(() => {
    const filteredBackups = filterBackups(backups, filters);
    setTotal(filteredBackups.length);
    setCurrentItems(paginateItems(filteredBackups, pagination.itemOffset, 10));
  }, [backups, filters, pagination.itemOffset]);

  const handleFilterChange = useCallback((filterType, filterValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: filterValue,
    }));
  }, []);

  const searchResult = {
    pagesetDocuments: currentItems,
    total,
  };

  return (
    <div style={{ position: "relative" }}>
      {product !== "symbolmate" && (
        <Link
          to="upload"
          className="btn btn-primary btn-lg pull-left"
          style={{ position: "absolute", right: 0, top: -204 }}
        >
          <Translation translate="MYTD_uploadBackupLbl" />
        </Link>
      )}
      <div className="row">
        {backups.length > 0 && (
          <div className="col-xs-12">
            <div className="clearfix">
              <div className="clearfix pull-right">
                <Pagination
                  setCurrentItems={setCurrentItems}
                  itemsPerPage={10}
                  handleFilterChange={handleFilterChange}
                  setPagination={setPagination}
                  pagination={pagination}
                  searchResult={searchResult}
                />
              </div>
              <div
                className="pull-left"
                style={{ marginBottom: 54, paddingTop: 48 }}
              >
                <FilterInput
                  value={filters.text}
                  onChange={(e) => handleFilterChange("text", e.target.value)}
                  onClear={() => handleFilterChange("text", "")}
                  placeholder={translations.MYTD_searchPlaceholder}
                />
              </div>
            </div>
          </div>
        )}

        <div className="col-xs-12" style={{ paddingTop: 10 }}>
          <List
            items={currentItems}
            product={product}
            translations={translations}
            type="backups"
            fetchPagesets={fetchBackups}
          />
        </div>

        {backups.length > 0 && (
          <div className="col-xs-12">
            <div className="clearfix">
              <Pagination
                setCurrentItems={setCurrentItems}
                itemsPerPage={10}
                handleFilterChange={handleFilterChange}
                setPagination={setPagination}
                pagination={pagination}
                searchResult={searchResult}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Backups;
