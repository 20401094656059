import React from "react";
import Translation from "../../../common/Translation/Index";
import { Modal } from "react-bootstrap";

const CancelModal = ({
  showModal,
  setShowModal,
  subItem,
  handleCancelSubscription,
  languageCode,
}) => {
  const closeModal = () => setShowModal(false);
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      className={showModal ? "in" : ""}
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            <Translation translate="MYTD_cancelRenewTxt" />
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Translation translate="MYTD_cancelRenewWarnMsg" />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <span>
          <a
            href="javascript:void(0)"
            className="btn btn-lg btn-primary margin-sm-left"
            onClick={() =>
              handleCancelSubscription(
                languageCode,
                subItem.id,
                subItem.productName,
              )
            }
          >
            <Translation translate="MYTD_yesTxt" />
          </a>
          <button onClick={closeModal} className="btn btn-lg btn-default">
            <Translation translate="MYTD_noTxt" />
          </button>
        </span>
      </Modal.Footer>
    </Modal>
  );
};
export default CancelModal;
