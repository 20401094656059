import React from "react";
import Translation from "../../common/Translation/Index";
import SystemDownloadButton from "../../common/Store/Common/SystemDownloadButton";

const Step2 = ({
  appLinksData,
  translations,
  navigator,
  stepNumber,
  replaceArray,
  download,
  ifSnapIsInstalledGoToNextStep,
}) => {
  return (
    <div className="col-md-4">
      <div className="well well-match jump-links text-center">
        <div className="icon icon-download-installer icon-68 data-icon"></div>
        <h3 className="trial-well-header">
          <Translation translate={stepNumber} replaceArray={replaceArray} /> -{" "}
          <Translation translate={download} />
        </h3>
        <div className="trial-well-body">
          <SystemDownloadButton
            url={appLinksData["windows-free"]}
            system={navigator?.userAgentData?.platform}
            translations={translations}
          />
          <div style={{ marginTop: "14px" }}>
            <SystemDownloadButton
              url={appLinksData["ios-free"]}
              system="AppStore"
              translations={translations}
            />
          </div>
        </div>
        <div className="trial-well-footer">
          <hr />
          <p>
            <Translation translate={ifSnapIsInstalledGoToNextStep} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Step2;
