import React, { useState, useEffect } from "react";
import Translation from "@common/Translation/Index";
import Checkbox from "@common/Checkbox";
import SharePscForm from "./SharePscForm";
import { putPagesetDetails } from "@services";
import toast from "react-hot-toast";

const PagesetEditForm = ({
  shouldPSC,
  translations,
  handleCancelClick,
  editableFields,
  setEditableFields,
  product,
  id,
  setPageset,
  type,
}) => {
  const { title, description, isPublic, author, languageCode, categories } =
    editableFields;
  const [shareToPsc, setShareToPsc] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  useEffect(() => {
    const errors = validateForm();
    setValidationErrors(errors);
    setIsFormInvalid(Object.keys(errors).length > 0);
  }, [editableFields, shareToPsc]);

  const handleTextUpdate = (e, field) => {
    const value = e && e.target ? e.target.value : e;

    setEditableFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const togglePublic = (selected) => {
    setEditableFields((prevFields) => ({
      ...prevFields,
      isPublic: selected,
    }));
  };

  const validateForm = () => {
    const titleLength = product === "snapscene" ? 100 : 200;
    const descLength = product === "snap" ? 1000 : 500;
    const { title, description, author, categories, languageCode } =
      editableFields;

    const errors = {};

    // Title validation
    if (title.trim().length === 0 || title.trim().length > titleLength) {
      errors.title =
        product === "snapscene"
          ? "MYTD_title100WarnMsg"
          : "MYTD_title200WarnMsg";
    }

    // Description validation
    if (description.trim().length > descLength) {
      errors.description =
        descLength === 500
          ? "MYTD_description500Msg"
          : "MYTD_description1000Msg";
    }

    // Additional validation when shareToPsc is true
    if (shareToPsc) {
      if (!author || author.trim().length === 0 || author.trim().length > 100) {
        errors.author = "MYTD_pscFormAuthError";
      }
      if (!languageCode) {
        errors.languageCode = "MYTD_langRequiredLbl";
      }
      if (!categories || categories.length === 0) {
        errors.categories = "MYTD_categoryRequiredLbl";
      }
    }

    return errors;
  };

  const handleShareCheckbox = (selected) => {
    setShareToPsc(selected);
    togglePublic(selected);
  };

  const handleUpdate = async () => {
    const payload = { ...editableFields, id };
    try {
      const data = await putPagesetDetails(product, payload, type);
      toast.success(translations.Changes_saved);
      setPageset(data);
      handleCancelClick();
    } catch (error) {
      console.error("Failed to save:", error);
      toast.error(translations.An_error_occurred_while_processing_your_request);
    }
  };

  return (
    <div id="backups">
      <div className="form-group margin-bottom-md">
        <label htmlFor="title" className="control-label">
          <Translation translate="MYTD_titleTxt" />
        </label>
        <input
          value={title}
          onChange={(e) => handleTextUpdate(e, "title")}
          required
          type="text"
          name="title"
          id="title"
          className="form-control text-box single-line"
          placeholder={translations.Title}
          data-testid="title-input"
        />
        {validationErrors.title && (
          <span className="help-block text-danger">
            <Translation translate={validationErrors.title} />
          </span>
        )}
      </div>
      <div className="form-group margin-bottom-md">
        <label htmlFor="description" className="control-label">
          <Translation translate="MYTD_descriptionLbl" />
        </label>{" "}
        <span className="optional">
          <Translation translate="MYTD_optionalTxt" />
        </span>
        <textarea
          value={description}
          onChange={(e) => handleTextUpdate(e, "description")}
          name="description"
          id="description"
          rows="5"
          className="form-control text-box multi-line"
          placeholder={translations.MYTD_descrTxt}
          data-testid="description-input"
        ></textarea>
        {validationErrors.description && (
          <span className="help-block text-danger">
            <Translation translate={validationErrors.description} />
          </span>
        )}
      </div>
      {shouldPSC() && (
        <>
          <div className="form-group psc-share-box">
            <Checkbox
              name={translations.MYTD_shareToPagesetCentralBtnTxt}
              label={translations.MYTD_shareToPagesetCentralBtnTxt}
              selected={isPublic}
              onSelectionChange={(value, selected) =>
                handleShareCheckbox(selected)
              }
            />
          </div>
          {shareToPsc && (
            <SharePscForm
              author={author}
              languageCode={languageCode}
              categories={categories}
              handleTextUpdate={handleTextUpdate}
              translations={translations}
              validationErrors={validationErrors}
            />
          )}
        </>
      )}
      <button
        className="btn btn-lg btn-primary"
        disabled={isFormInvalid}
        onClick={handleUpdate}
        data-testid="update-button"
      >
        <Translation translate="Update" />
      </button>{" "}
      <button
        className="btn btn-lg btn-default"
        data-testid="cancel-button"
        onClick={handleCancelClick}
      >
        <Translation translate="MYTD_cancelBtnTxt" />
      </button>
    </div>
  );
};

export default PagesetEditForm;
