import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TileSet from "@common/Tile/TileSet";
import Translation from "@common/Translation/Index";
import { getUserStuff, getUserNotificationCount } from "@services";

const UsersStuffHome = () => {
  const { translations } = useSelector((state) => state.translations);
  const { languageCode } = useSelector((state) => state.languageData);
  const { userDetail } = useSelector((state) => state.user);

  const [notificationsCount, setNotificationsCount] = useState(1);
  const [userStuff, setUserStuff] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const userStuffData = await getUserStuff(languageCode);
      setUserStuff(userStuffData);

      const notificationsData = await getUserNotificationCount(languageCode);
      setNotificationsCount(notificationsData);
    };

    fetchData();
  }, [languageCode]);

  return (
    <>
      <div className="container mystuff-nav fade-block fade-in">
        <h2>
          <Translation translate="My_Stuff" textonly="true" />
        </h2>
        <TileSet
          notifications={notificationsCount}
          avatar={userDetail.avatarUrl}
          showAvatar="true"
          showNotifications="true"
          tilegroup={userStuff.myStuff}
          translation={translations}
        />
      </div>
      {userStuff.moreStuff && userStuff.moreStuff.length > 0 && (
        <div className="container-full">
          <div className="container morestuff-nav">
            <h2>
              <Translation translate="More_Stuff" textonly="true" />
            </h2>
            <TileSet
              tilegroup={userStuff.moreStuff}
              isMoreStuff="true"
            ></TileSet>
          </div>
        </div>
      )}
    </>
  );
};

export default UsersStuffHome;
