import React from "react";
import { Modal } from "react-bootstrap";
import Translation from "@common/Translation/Index";

const RemoveSyncModal = ({
  showModal,
  setShowModal,
  title,
  handleRemoveSync,
}) => {
  const closeModal = () => setShowModal(false);

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      className={showModal ? "in" : ""}
      data-testid="delete-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            <Translation translate="MYTD_removeSyncLbl" />
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Translation translate="MYTD_stopSyncRemoveMsg" /> {title}?
        </p>
        <p>
          <Translation translate="MYTD_stopSyncWarnMsg1" />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <span>
          <button onClick={handleRemoveSync} className="btn btn-lg btn-primary">
            <Translation translate="MYTD_stopSynchingLbl" />
          </button>
          <button onClick={closeModal} className="btn btn-lg btn-default">
            <Translation translate="MYTD_cancelBtnTxt" />
          </button>
        </span>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveSyncModal;
