import React, { Children, useState, useCallback } from "react";
import { Calendar, Views } from "react-big-calendar";
import moment from "moment";
import "moment-timezone";
import Translation from "@common/Translation/Index";
import CalendarToolbar from "./CalendarToolbar";
import { formats, localizer } from "./utils";

const bgEvent = ({ event }) => (
  <div className="custom-day-label">
    {event.words}{" "}
    <Translation translate="MYTD_MBtabLblMessages" textonly="true" />
  </div>
);

const CalendarComponent = ({
  events,
  viewChoice,
  dateChoice,
  handleModelingChange,
  selectedModelingOption,
  isModeling,
  modelingOptions,
  setDateChoice,
  setViewChoice,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const togglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  const eventStyleGetter = useCallback(
    (event) => ({
      style: {
        backgroundColor: `#${event.hex}`,
        borderRadius: "0px",
        color: "white",
        border: "0px",
        display: "block",
      },
    }),
    [],
  );

  const ColoredDateCellWrapper = ({ children, value }) => {
    const eventHex = events.eventsWithHex
      ? events.eventsWithHex.reduce((hex, { start, hex: eventHex, count }) => {
          const cStart = new Date(start).getTime();
          const cValue = new Date(value).getTime();
          return cStart === cValue
            ? count === 0
              ? "#000000"
              : `#${eventHex}`
            : hex;
        }, "white")
      : [];

    return React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: eventHex,
        cursor: "pointer",
      },
    });
  };

  const dateHeaderWrapper = ({ date, label }) => {
    const hasDataClass = events.eventsWithHex
      ? events.eventsWithHex.some(
          ({ start }) => new Date(start).getTime() === new Date(date).getTime(),
        )
        ? "hasDataZ"
        : ""
      : "";
    return <div className={hasDataClass}>{label}</div>;
  };

  const handleViewSwitchClick = (view) => {
    setViewChoice(view);
  };

  const handleButtonNav = (direction) => {
    const unit = viewChoice === Views.MONTH ? "months" : "days";
    const newDate = moment(dateChoice)
      [direction === "prev" ? "subtract" : "add"](1, unit)
      .toString();
    setDateChoice(newDate);
  };

  const handlePickerSelect = (date) => {
    setDateChoice(date);
    togglePopover();
  };

  return (
    <div className="big-cal">
      <CalendarToolbar
        eventMin={events.min}
        eventMax={events.max}
        isPopoverOpen={isPopoverOpen}
        togglePopover={togglePopover}
        handleViewSwitchClick={handleViewSwitchClick}
        handleButtonNav={handleButtonNav}
        pickerDate={dateChoice}
        handlePickerSelect={handlePickerSelect}
        viewChoice={viewChoice}
        handleModelingChange={handleModelingChange}
        selectedModelingOption={selectedModelingOption}
        modelingOptions={modelingOptions}
        isModeling={isModeling}
      />
      <div className="heatmap">
        <Calendar
          localizer={localizer}
          className="demo"
          events={events.eventsWithHex}
          views={{ day: true, month: true }}
          date={dateChoice}
          view={viewChoice}
          onNavigate={setDateChoice}
          onView={setViewChoice}
          step={30}
          formats={formats}
          selectable
          onSelectSlot={null}
          components={{
            dateCellWrapper: ColoredDateCellWrapper,
            month: { dateHeader: dateHeaderWrapper },
            day: { event: bgEvent },
          }}
          toolbar={false}
          eventPropGetter={eventStyleGetter}
          isModeling={isModeling}
        />
      </div>
    </div>
  );
};

export default CalendarComponent;
