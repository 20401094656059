import React from "react";
import Translation from "../Translation/Index";

const SubProductsList = ({ subProducts }) => (
  <div className="container text-center">
    <div className="row">
      <div className="col-sm-8 col-sm-push-2">
        <h2>
          <Translation translate="Snap_store_choose_variety_pagesets"></Translation>
        </h2>
      </div>
    </div>
    {subProducts.map((prod, index) => (
      <div
        key={index}
        className="well well-white text-left"
        style={{ padding: "0 15px" }}
      >
        <div className="row equal">
          <div className="col-12 col-md-8 equal-content equal-main">
            <p key={`${prod.name}-${index}`}>
              <strong>{prod.name}</strong>
              <br />
              {prod.notes.map((note, index) => (
                <span key={`${note}-${index}`}>{note}</span>
              ))}
            </p>
          </div>
        </div>
      </div>
    ))}
  </div>
);

export default SubProductsList;
