import React from "react";
import Translation from "@common/Translation/Index";
import { Modal } from "react-bootstrap";
const ProfileModal = ({
  showModal,
  toggleModal,
  handleDistCodeDelete,
  profile,
}) => {
  const closeModal = () => toggleModal();
  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      className={showModal ? "in" : ""}
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            <Translation translate="MYTD_deleteLbl" textonly="true" />{" "}
            <Translation translate="Dist_Org_Code" textonly="true" />
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Translation translate="Dist_Code_Delete_Warning" textonly="true" />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <span>
          <button
            onClick={toggleModal}
            data-testid="cancel-button"
            className="btn btn-lg btn-default"
          >
            <Translation translate="MYTD_cancelBtnTxt" textonly="true" />
          </button>
          <button
            aria-label="Close"
            onClick={(e) => {
              handleDistCodeDelete(e, profile.bmoDistrictCode);
              toggleModal();
            }}
            className="btn btn-lg btn-primary"
            data-testid="delete-button"
          >
            <Translation translate="MYTD_deleteLbl" textonly="true" />
          </button>
        </span>
      </Modal.Footer>
    </Modal>
  );
};
export default ProfileModal;
