import React from "react";
import { useSelector } from "react-redux";
import Translation from "@common/Translation/Index";
import Header from "./Header";
import UsersStuffHome from "./UsersStuffHome";

export const Dashboard = () => {
  const { translations } = useSelector((state) => state.translations);
  const { userDetail } = useSelector((state) => state.user);

  return (
    <>
      <div id="dashboard">
        {userDetail && (
          <>
            <Header
              title={
                <Translation
                  pre=""
                  translate={translations.MYTD_siteGreeting}
                  post={`${userDetail.firstName}!`}
                />
              }
            />

            <UsersStuffHome />
          </>
        )}
      </div>
    </>
  );
};
