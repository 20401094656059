import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cancelSubscription, getUserSubscriptions } from "@services";
import Translation from "../../../common/Translation/Index";
import SubscriptionHeader from "./SubscriptionHeader";
import SubscriptionDetails from "./SubscriptionDetails";
import ContractDates from "./ContractDates";
import SubButtons from "./SubButtons";
import CancelModal from "./CancelModal";
import { momentLocaleFix } from "../../../../helpers/utilities";
import { toast } from "react-hot-toast";

const Subscriptions = () => {
  const { languageCode } = useSelector((state) => state.languageData);
  const [subscriptions, setSubscriptions] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { translations } = useSelector((state) => state.translations);

  useEffect(() => {
    const fetchUserSubscriptions = async () => {
      const data = await getUserSubscriptions(languageCode);
      setSubscriptions(data);
    };
    fetchUserSubscriptions();
  }, [languageCode]);

  const handleCancelSubscription = async (langCode, id, product) => {
    try {
      await cancelSubscription(langCode, id, product);
      toast.success(translations.Subscription_Cancelled);
    } catch (error) {
      toast.error(
        translations.An_error_occurred_while_processing_your_request,
        error,
      );
    }
  };

  return (
    <div>
      <br />
      <div className="row">
        <div className="col-xs-12"></div>
        {subscriptions && subscriptions.length > 0 ? (
          subscriptions.map((subItem, subIndex) => {
            return (
              <div
                key={subIndex}
                className="well well-clearfix subscription-well margin-top-md subscription-block fade-in"
              >
                <SubscriptionHeader subItem={subItem} />
                <SubscriptionDetails subItem={subItem} />
                <ContractDates
                  subItem={subItem}
                  momentLocaleFix={momentLocaleFix}
                />
                <SubButtons
                  subItem={subItem}
                  setShowModal={setShowCancelModal}
                />
                <CancelModal
                  subItem={subItem}
                  showModal={showCancelModal}
                  setShowModal={setShowCancelModal}
                  handleCancelSubscription={handleCancelSubscription}
                  languageCode={languageCode}
                />
              </div>
            );
          })
        ) : (
          <h4>
            <Translation translate="MYTD_noActiveSubsMsg" />
          </h4>
        )}
      </div>
    </div>
  );
};
export default Subscriptions;
