import React, { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { checkAuthStatus, getAntiForgeryToken } from "@services";
import { setAuthentication } from "../../../actions";

const AuthContext = createContext();

const scheduleLoginCheck = (checkAuthStatus) => {
  const expirationCookie = Cookies.get("login-expiration-utc");
  if (expirationCookie) {
    const expirationTime = JSON.parse(expirationCookie).Expired * 1000;
    const currentTime = Date.now();
    const timeUntilExpiration = expirationTime - currentTime;

    if (timeUntilExpiration > 0) {
      const timeout = setTimeout(() => {
        checkAuthStatus();
        scheduleLoginCheck(checkAuthStatus);
      }, timeUntilExpiration);

      return () => clearTimeout(timeout);
    } else {
      checkAuthStatus();
    }
  }
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await checkAuthStatus();
        setIsAuthenticated(data);
        dispatch(setAuthentication(data));

        // Fetch Antiforgery token only if the user is authenticated
        // The only endpoint that uses POST form is behind auth
        if (data) {
          await fetchAntiForgeryToken();
        }
      } catch (error) {
        console.error("Error checking authentication status:", error);
      }
    };

    const fetchAntiForgeryToken = async () => {
      try {
        await getAntiForgeryToken();
      } catch (error) {
        console.error("Error fetching anti-forgery token:", error);
      }
    };

    fetchData();

    const cleanup = scheduleLoginCheck(fetchData);
    return () => cleanup && cleanup();
  }, [dispatch]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
