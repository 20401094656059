import React from "react";
import Translation from "@common/Translation/Index";

const CityDetailsComp = ({ handleTextUpdate, profile }) => {
  return (
    <div className="row">
      <div className="col-sm-6 col-md-4 form-group">
        <label className="control-label" htmlFor="city">
          {<Translation translate={"MYTD_cityLbl"}></Translation>}
        </label>{" "}
        <span className="optional">
          {<Translation translate={"MYTD_optionalTxt"}></Translation>}
        </span>
        <input
          className="form-control"
          id="city"
          name="address.city"
          type="text"
          data-testid="cityInput"
          value={profile.address.city || ""}
          onChange={handleTextUpdate}
          key={"city"}
        />
      </div>
      <div className="col-sm-6 col-md-4 form-group">
        <label className="control-label" htmlFor="state">
          {<Translation translate={"MYTD_stateProvLbl"}></Translation>}
        </label>{" "}
        <span className="optional">
          {<Translation translate={"MYTD_optionalTxt"}></Translation>}
        </span>
        <input
          className="form-control"
          id="state"
          name="address.state"
          type="text"
          value={profile.address.state || ""}
          onChange={handleTextUpdate}
          key={"state"}
        />
      </div>
      <div className="col-sm-6 col-md-4 form-group">
        <label className="control-label" htmlFor="zipCode">
          {<Translation translate={"MYTD_postalCodeLbl"}></Translation>}
        </label>
        <input
          className="form-control"
          id="zipCode"
          name="address.zipCode"
          type="text"
          data-testid="zipCodeInput"
          value={profile.address.zipCode || ""}
          onChange={handleTextUpdate}
          key={"zipCode"}
        />
      </div>
      <div className="col-sm-6 col-md-4 form-group">
        <label className="control-label" htmlFor="phoneNumber">
          {<Translation translate={"MYTD_phoneNumberLbl"}></Translation>}
        </label>{" "}
        <span className="optional">
          {<Translation translate={"MYTD_optionalTxt"}></Translation>}
        </span>
        <input
          className="form-control"
          id="phoneNumber"
          name="address.phoneNumber"
          type="text"
          value={profile.address.phoneNumber || ""}
          onChange={handleTextUpdate}
          key={"phoneNumber"}
        />
      </div>
    </div>
  );
};
export default CityDetailsComp;
