import React, { useEffect, useState, useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import Translation from "@common/Translation/Index";
import PagesetBox from "@common/PagesetBox/Index";
import Panel from "@common/Panel/Index";
import {
  getLatestPageset,
  getLatestBackup,
  getDocumentationLinks,
} from "@services";
import WarningMessage from "./WarningMessage";

const Overview = () => {
  const { product, languageCode, tabsData } = useOutletContext();
  const [latestPageset, setLatestPageset] = useState();
  const [latestBackup, setLatestBackup] = useState();
  const [links, setLinks] = useState({
    downloads: [],
    applications: [],
    training: [],
    resources: [],
    documentation: [],
  });

  const hasPagesets = useMemo(
    () => tabsData.tabs.some((tab) => tab.key === "MYTD_PagesetsLbl"),
    [tabsData],
  );
  const hasBackups = useMemo(
    () => tabsData.tabs.some((tab) => tab.key === "MYTD_BackupsLbl"),
    [tabsData],
  );

  const fetchLatestPageset = async () => {
    if (hasPagesets) {
      try {
        const pagesetData = await getLatestPageset(languageCode, product);
        setLatestPageset(pagesetData);
      } catch (error) {
        console.error("Error fetching latest pageset:", error);
      }
    }
  };

  const fetchLatestBackup = async () => {
    if (hasBackups) {
      try {
        const backupData = await getLatestBackup(languageCode, product);
        setLatestBackup(backupData);
      } catch (error) {
        console.error("Error fetching latest backup:", error);
      }
    }
  };

  const fetchLinks = async () => {
    try {
      const data = await getDocumentationLinks(languageCode, product);
      setLinks(data);
    } catch (error) {
      console.error("Error fetching links:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchLatestPageset();
      await fetchLatestBackup();
      await fetchLinks();
    };

    fetchData();
  }, [hasPagesets, hasBackups, languageCode, product]);

  return (
    <>
      <WarningMessage product={product} />
      <div
        className="row"
        style={{ marginTop: !hasBackups || !hasPagesets ? "25px" : "" }}
      >
        {hasPagesets && (
          <div className={`col-sm-12 ${hasBackups ? "col-md-6" : ""}`}>
            <h3>
              <Translation translate="MYTD_RecentPagesetLbl" />
            </h3>
            <div className="row">
              {latestPageset ? (
                <PagesetBox
                  data={latestPageset}
                  product={product}
                  type="pagesets"
                  languageCode={languageCode}
                  fetchPagesets={fetchLatestPageset}
                />
              ) : (
                <div className="col-xs-12" style={{ marginTop: "20px" }}>
                  <Translation translate="MYTD_noRecentPagesetLbl" />
                </div>
              )}
            </div>
          </div>
        )}
        {hasBackups && (
          <div className={`col-sm-12 ${hasPagesets ? "col-md-6" : ""}`}>
            <h3>
              <Translation translate="MYTD_RecentBackupLbl" />
            </h3>
            <div className="row">
              {latestBackup ? (
                <PagesetBox
                  data={latestBackup}
                  product={product}
                  type="backups"
                  languageCode={languageCode}
                  fetchPagesets={fetchLatestBackup}
                />
              ) : (
                <div className="col-xs-12" style={{ marginTop: "20px" }}>
                  <Translation translate="MYTD_noRecentBackupLbl" />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <br />
      {product !== "symbolmate" && (
        <div className="row myStuff-panel-list" data-testid="panel-list">
          {product !== "messagebank" && (
            <div className="col-xs-12 col-sm-6 col-md-3">
              <Panel
                title={<Translation translate="MYTD_panelTitleDownloads" />}
                icon="icon-download"
                urls={links.downloads}
              />
            </div>
          )}
          {product === "messagebank" && (
            <div className="col-xs-12 col-sm-6 col-md-3">
              <Panel
                title={<Translation translate="MYTD_panelTitleMessageBank" />}
                icon="icon-logo-message-bank"
                urls={links.applications}
              />
            </div>
          )}
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Panel
              title={<Translation translate="MYTD_panelTitleTraining" />}
              icon="icon-training"
              urls={links.training}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Panel
              title={<Translation translate="MYTD_panelTitleResources" />}
              icon="icon-get-support"
              urls={links.resources}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Panel
              title={<Translation translate="MYTD_panelTitleDocumentation" />}
              icon="icon-resources"
              urls={links.documentation}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Overview;
