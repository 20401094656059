import React from "react";

const Checkbox = ({ selected, name, label, onSelectionChange }) => (
  <span className="checkbox checkbox-link checkbox-inline custom">
    <input
      checked={selected}
      name={name}
      id={name}
      type="checkbox"
      onChange={() => onSelectionChange(name, !selected)}
      data-testid="checkbox"
    />
    <label htmlFor={name}> {label}</label>
  </span>
);

export default Checkbox;
