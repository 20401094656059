import React, { useState } from "react";
import Translation from "@common/Translation/Index";
import DetachButton from "./DetachButton";
import DeactivateModal from "./DeactivateModal";

const DeviceAttributes = ({
  device,
  translations,
  tokenLabel,
  fetchDevices,
}) => {
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  return (
    <div>
      {device.softwareVersion && (
        <span className="data">
          <Translation translate="MYTD_versionLbl" textonly="true" />{" "}
          {device.softwareVersion}
        </span>
      )}
      {device.numberOfPages && (
        <span className="data">
          {device.numberOfPages}{" "}
          <Translation translate="MYTD_pagesLbl" textonly="true" />
        </span>
      )}
      {device.availableSpace && (
        <span className="data">
          {device.availableSpace}{" "}
          <Translation translate="MYTD_availableLbl" textonly="true" />
        </span>
      )}
      {device.requiresSpeakingPermission && (
        <div>
          <Translation translate="MYTD_SpeakingPermLbl" textonly="true" />
          {device.speakingPermission ? (
            <>
              <span className="data">
                {" "}
                {tokenLabel(device.speakingPermission, translations)}{" "}
              </span>
              <DetachButton
                onClick={() => setShowDeactivateModal(true)}
                translationKey="MYTD_deactivateLbl"
              />
            </>
          ) : (
            <span className="data">
              <Translation translate="MYTD_noneLbl" textonly="true" />
            </span>
          )}
        </div>
      )}
      {device.inAppSpeakingPermission && (
        <span>
          <Translation translate="MYTD_speakingPermLbl" textonly="true" />{" "}
          <span className="data">
            {device.licenseKey ? (
              <Translation
                translate="MYTD_includedLicenseLbl"
                textonly="true"
              />
            ) : (
              <Translation translate="MYTD_includedAppLbl" textonly="true" />
            )}
          </span>{" "}
        </span>
      )}
      {device.providesSpeakingCompanion && (
        <span>
          <Translation translate="MYTD_SpeakingCompLbl" textonly="true" />{" "}
          <span className="data">
            {device.speakingCompanion || (
              <Translation translate="MYTD_notAssignedLbl" textonly="true" />
            )}
          </span>
        </span>
      )}
      {device.licenseKey && (
        <span>
          <DetachButton
            onClick={() => setShowDeactivateModal(true)}
            translationKey="MYTD_deactivateLbl"
          />
        </span>
      )}
      <DeactivateModal
        device={device}
        showModal={showDeactivateModal}
        setShowModal={setShowDeactivateModal}
        fetchDevices={fetchDevices}
        translations={translations}
      />
    </div>
  );
};

export default DeviceAttributes;
