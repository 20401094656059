import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Moment from "react-moment";
import Table from "@common/Table";
import Translation from "@common/Translation/Index";
import DeleteModal from "./Books/DeleteModal";
import { getStudents } from "@services";

const Students = () => {
  const { languageCode, translations } = useOutletContext();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeItem, setActiveItem] = useState({});
  const [students, setStudents] = useState([]);

  const fetchStudents = async () => {
    const data = await getStudents(languageCode);
    setStudents(data);
  };

  useEffect(() => {
    fetchStudents();
  }, [languageCode]);

  const cols = [
    <Translation translate="MYTD_backupDateLbl" key="MYTD_backupDateLbl" />,
    <Translation translate="MYTD_studentNameLbl" key="MYTD_studentNameLbl" />,
    "",
  ];

  if (students.length <= 0) {
    return (
      <div className="col-xs-12">
        <p className="no-data-message" style={{ marginLeft: 0 }}>
          <Translation translate="No_students_found_" />
        </p>
      </div>
    );
  }

  return (
    <div>
      <Table cols={cols}>
        {students.map((item) => {
          return (
            <tr key={item.id}>
              <td style={{ width: "125px" }}>
                <Moment format="l">{item.backupDateUtc}</Moment>
              </td>
              <td>{item.name}</td>
              <td>
                <span>
                  <a
                    href="#"
                    onClick={() => {
                      setActiveItem(item);
                      setShowDeleteModal(true);
                    }}
                  >
                    <Translation translate="MYTD_deleteLbl" />
                  </a>
                </span>
              </td>
            </tr>
          );
        })}
      </Table>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        name={activeItem.name}
        translations={translations}
        fetchFn={fetchStudents}
        id={activeItem.id}
        type="students"
      />
    </div>
  );
};

export default Students;
