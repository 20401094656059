import React from "react";
import Translation from "@common/Translation/Index";
import { useSelector } from "react-redux";

const ProfessionalComp = ({
  organization,
  handleOrganizationChange,
  occupationId,
  handleOccupationIdChange,
  industryId,
  handleIndustryIdChange,
}) => {
  const { translations } = useSelector((state) => state.translations);
  return (
    <div className="row" id="professionalInfo">
      <div className="col-sm-6 col-md-4 form-group">
        <label className="control-label" htmlFor="organization">
          <Translation translate={"MYTD_orgNameLbl"}></Translation>
        </label>{" "}
        <span className="optional">
          <Translation translate={"MYTD_optionalTxt"}></Translation>
        </span>
        <input
          className="form-control"
          id="organization"
          name="organization"
          type="text"
          value={organization}
          onChange={handleOrganizationChange}
        />
      </div>
      <div className="col-sm-6 col-md-4 form-group">
        <label className="control-label" htmlFor="occupationId">
          <Translation translate={"MYTD_RoleLbl"}></Translation>
        </label>{" "}
        <span className="optional">
          <Translation translate={"MYTD_optionalTxt"}></Translation>
        </span>
        <select
          className="form-control"
          value={occupationId}
          onChange={handleOccupationIdChange}
          id="occupationId"
          name="occupationId"
        >
          <option value="0">{translations.MYTD_selectRoleLbl}</option>
          <option value="18">{translations.MYTD_pathologistLbl}</option>
          <option value="19">{translations.MYTD_atSpecLbl}</option>
          <option value="20">{translations.MYTD_specEdLbl}</option>
          <option value="21">{translations.MYTD_genEdLbl}</option>
          <option value="22">{translations.MYTD_healthProLbl}</option>
        </select>
      </div>
      <div className="col-sm-6 col-md-4 form-group">
        <label className="control-label" htmlFor="industryId">
          <Translation translate={"MYTD_industryLbl"}></Translation>
        </label>{" "}
        <span className="optional">
          <Translation translate={"MYTD_optionalTxt"}></Translation>
        </span>
        <select
          className="form-control"
          value={industryId}
          onChange={handleIndustryIdChange}
          id="industryId"
          name="industryId"
        >
          <option value="0">{translations.MYTD_selectindLbl}</option>
          <option value="1">{translations.MYTD_healthCareLbl}</option>
          <option value="2">{translations.MYTD_educationLbl}</option>
          <option value="3">{translations.MYTD_otherLbl}</option>
        </select>
      </div>
    </div>
  );
};
export default ProfessionalComp;
