import React from "react";
import Modal from "react-bootstrap/Modal";
import Translation from "@common/Translation/Index";
import toast from "react-hot-toast";
import { detachDeviceById } from "@services";

const DetachModal = ({
  device,
  showModal,
  setShowModal,
  translations,
  product,
  fetchDevices,
}) => {
  const closeModal = () => setShowModal(false);

  const detachMessages = {
    detachMessage:
      "Detaching this device will detach any companions associated with the device.",
    detachPerpetualMessage: "Are you sure you want to detach this app?",
    deactivateMessage:
      "Are you sure you want to detach this device? Proceeding will do the following:",
    deactivateWarning:
      "All usage data gathered to date will be deleted. If you have not stopped collecting data in your software, new data will be reported and displayed.",
  };

  const companions = {};
  if (!!device.companions && !!device.companions.length) {
    companions.speech = [];
    companions.podd = [];
    companions.gateway = [];
    device.companions.filter((entry) => {
      if (entry.key === "com.tobiidynavox.snap.speech") {
        companions.speech.push(entry.value);
        companions.speech = companions.speech.sort((a, b) =>
          a.toLowerCase().localeCompare(b.toLowerCase()),
        );
      }
      return false;
    });
    device.companions.filter((entry) => {
      if (entry.key === "com.tobiidynavox.snap.podd") {
        companions.podd.push(entry.value);
        companions.podd = companions.podd.sort((a, b) =>
          a.toLowerCase().localeCompare(b.toLowerCase()),
        );
      }
      return false;
    });
    device.companions.filter((entry) => {
      if (entry.key === "com.tobiidynavox.snap.gateway") {
        companions.gateway.push(entry.value);
        companions.gateway = companions.gateway.sort((a, b) =>
          a.toLowerCase().localeCompare(b.toLowerCase()),
        );
      }
      return false;
    });

    companions.speech = companions.speech.length
      ? companions.speech.join(", ")
      : companions.speech;
    companions.podd = companions.podd.length
      ? companions.podd.join(", ")
      : companions.podd;
    companions.gateway = companions.gateway.length
      ? companions.gateway.join(", ")
      : companions.gateway;
  }

  const companionPermissions = {
    thisTitle: device.title,
    poddCompanionPermission:
      device.companionPermissions?.includes("com.tobiidynavox.snap.podd") ||
      false,
    gatewayCompanionPermission:
      device.companionPermissions?.includes("com.tobiidynavox.snap.gateway") ||
      false,
    speechPermission:
      device.companionPermissions?.includes("com.tobiidynavox.snap.speech") ||
      false,
  };

  const handleDetach = async () => {
    try {
      await detachDeviceById(product, device.id, device.detachLink);
      await new Promise((resolve) => setTimeout(resolve, 200));
      toast.success(translations.Changes_saved);
      await fetchDevices();
      closeModal();
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error(translations.An_error_occurred_while_processing_your_request);
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        className={showModal ? "in" : ""}
      >
        <Modal.Header>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <Modal.Title>
            {device.detachLink ? (
              <h2>
                <Translation translate="MYTD_DetachDeviceLbl" />
              </h2>
            ) : (
              <h2>
                <Translation translate="MYTD_deactivateLbl" />
              </h2>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {device.detachLink
              ? device.isPerpertual
                ? detachMessages.detachPerpetualMessage
                : detachMessages.detachMessage
              : detachMessages.deactivateMessage}
          </p>
          {!device.detachLink && (
            <table className="table devices SnapDetachModal">
              <tbody>
                <tr>
                  <td>
                    <strong>
                      <Translation translate="MYTD_DetachDeviceLbl" />
                    </strong>
                  </td>
                  <td>
                    <p>{device.title}</p>
                    {device.inAppSpeakingPermission && (
                      <span>
                        {" "}
                        {device.softwareTitle}{" "}
                        <Translation translate="MYTD_detachWarnMsg2" />
                      </span>
                    )}
                  </td>
                </tr>
                {!!companions.speech && (
                  <tr>
                    <td>
                      <strong>
                        <Translation translate="MYTD_detachSpeakingLbl" />
                      </strong>
                    </td>
                    <td>
                      <p>{companions.speech}</p>
                      <span>
                        <Translation translate="MYTD_detachDeviceWarn" />
                      </span>
                    </td>
                  </tr>
                )}
                {!!companions.podd && (
                  <tr>
                    <td>
                      <strong>
                        <Translation translate="Deactivate" /> PODD{" "}
                        <Translation translate="Companion" />
                      </strong>
                    </td>
                    <td>
                      <p>{companions.podd}</p>
                      <span>
                        <Translation
                          translate="Deactivate_companion"
                          textonly={true}
                          replaceArray={["PODD", "PODD"]}
                        />
                      </span>
                    </td>
                  </tr>
                )}
                {!!companions.gateway && (
                  <tr>
                    <td>
                      <strong>
                        <Translation translate="Deactivate" /> Gateway{" "}
                        <Translation translate="Companion" />
                      </strong>
                    </td>
                    <td>
                      <p>{companions.gateway}</p>
                      <span>
                        <Translation
                          translate="Deactivate_companion"
                          textonly={true}
                          replaceArray={["Gateway", "Gateway"]}
                        />
                      </span>
                    </td>
                  </tr>
                )}
                {!!companionPermissions.speechPermission && (
                  <tr>
                    <td>
                      <strong>
                        <Translation translate="MYTD_deactivateSpeechLbl" />
                      </strong>
                    </td>
                    <td>
                      <span>
                        <Translation translate="MYTD_deactivateWarnMsg2" />
                      </span>
                    </td>
                  </tr>
                )}

                {!!companionPermissions.gatewayCompanionPermission && (
                  <tr>
                    <td>
                      <strong>
                        <Translation translate="Deactivate" /> Gateway
                      </strong>
                    </td>
                    <td>
                      <span>
                        <Translation
                          translate="Deactivate_permission"
                          textonly={true}
                          replaceArray={["Gateway"]}
                        />
                      </span>
                    </td>
                  </tr>
                )}

                {!!companionPermissions.poddCompanionPermission && (
                  <tr>
                    <td>
                      <strong>
                        <Translation translate="Deactivate" /> PODD
                      </strong>
                    </td>
                    <td>
                      <span>
                        <Translation
                          translate="Deactivate_permission"
                          textonly={true}
                          replaceArray={["PODD"]}
                        />
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
          {!device.detachLink && <p>{detachMessages.deactivateWarning}</p>}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleDetach} className="btn btn-lg btn-primary">
            <Translation translate="MYTD_detachLbl" />
          </button>
          <button onClick={closeModal} className="btn btn-lg btn-default">
            <Translation translate="MYTD_cancelBtnTxt" />
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DetachModal;
