import React, { useState, useEffect } from "react";
import Select from "react-select";
import Translation from "@common/Translation/Index";
import { getPagesetCategories, getPagesetLanguages } from "@services";

const SharePscForm = ({
  author,
  languageCode,
  categories,
  handleTextUpdate,
  translations,
  validationErrors,
}) => {
  const [selectedLanguageCode, setSelectedLanguageCode] = useState(
    languageCode || "",
  );
  const [selectedCategories, setSelectedCategories] = useState(
    categories.map((id) => ({ value: id, label: `Category ${id}` })) || [],
  );
  const [languages, setLanguages] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);

  useEffect(() => {
    const fetchLanguages = async () => {
      const data = await getPagesetLanguages();
      setLanguages(data);
    };

    const fetchCategories = async () => {
      const data = await getPagesetCategories();
      const normalizedCategories = data.map((category) => ({
        value: category.id || category.value,
        label: category.name || category.label,
      }));
      setCategoriesList(normalizedCategories);
    };

    fetchLanguages();
    fetchCategories();
  }, []);

  const handleLanguageChange = (event) => {
    const selectedLanguageCode = event.target.value;
    setSelectedLanguageCode(selectedLanguageCode);
    handleTextUpdate(event, "languageCode");
  };

  const handleCategoryChange = (selectedOptions) => {
    const selectedCategoryIds = selectedOptions.map((option) => option.value);
    setSelectedCategories(selectedOptions);
    handleTextUpdate(selectedCategoryIds, "categories");
  };

  return (
    <>
      <p>
        <Translation translate="MYTD_shareToPagesetCentralDescTxt" />
      </p>
      <div className="form-group">
        <label htmlFor="author" className="control-label">
          <Translation translate="MYTD_authorLbl" />
        </label>
        <input
          value={author || ""}
          onChange={(e) => handleTextUpdate(e, "author")}
          required
          type="text"
          name="author"
          id="author"
          className="form-control text-box single-line"
          placeholder={translations.MYTD_authorLbl}
        />
        {validationErrors.author && (
          <span className="help-block text-danger">
            <Translation translate={validationErrors.author} />
          </span>
        )}
      </div>
      <div className="form-group">
        <label className="control-label" htmlFor="countryID">
          <Translation translate="MYTD_languageLbl" />
        </label>
        <select
          value={selectedLanguageCode}
          onChange={handleLanguageChange}
          className="form-control"
          id="languageCode"
          name="languages.languageCode"
        >
          {/* Placeholder option */}
          <option value="" disabled>
            {translations.Please_select_a_country}
          </option>
          {languages.map((lang) => (
            <option key={lang.code} value={lang.code}>
              {lang.name}
            </option>
          ))}
        </select>
        {validationErrors.languageCode && (
          <span className="help-block text-danger">
            <Translation translate={validationErrors.languageCode} />
          </span>
        )}
      </div>
      <div className="form-group multi-select">
        <label>
          <Translation translate="MYTD_categoryLbl" />
        </label>
        <div className="row">
          <Select
            options={categoriesList}
            value={selectedCategories}
            isMulti
            name="categories"
            className="categ-select"
            classNamePrefix="categ"
            closeMenuOnSelect={false}
            onChange={handleCategoryChange}
            placeholder={translations.select_category}
          />
          {validationErrors.categories && (
            <span className="help-block text-danger">
              <Translation translate={validationErrors.categories} />
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default SharePscForm;
