import React from "react";
import Translation from "@common/Translation/Index";

const FormErrors = ({ errors }) => {
  return (
    <span id="form-errors" className="field-validation-error">
      {errors.length > 0 && (
        <ul className="margin-bottom-md">
          {errors.map((error, index) => (
            <li key={index} className="has-error">
              <Translation translate={error.key} />
            </li>
          ))}
        </ul>
      )}
    </span>
  );
};

export default FormErrors;
