import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Moment from "react-moment";
import Table from "@common/Table";
import Translation from "@common/Translation/Index";
import ShareModal from "./ShareModal";
import DeleteModal from "./DeleteModal";
import { getBooks } from "@services";

const Books = () => {
  const { languageCode, translations } = useOutletContext();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [activeItem, setActiveItem] = useState({});
  const [books, setBooks] = useState([]);

  const fetchBooks = async () => {
    const data = await getBooks(languageCode);
    setBooks(data);
  };

  useEffect(() => {
    fetchBooks();
  }, [languageCode]);

  const cols = [
    <Translation translate="MYTD_backupDateLbl" key="MYTD_backupDateLbl" />,
    <Translation translate="MYTD_titleTxt" key="MYTD_titleTxt" />,
    "",
  ];

  if (books.length <= 0) {
    return (
      <div className="col-xs-12">
        <p className="no-data-message" style={{ marginLeft: 0 }}>
          <Translation translate="No_books_found_" />
        </p>
      </div>
    );
  }

  return (
    <div>
      <Table cols={cols}>
        {books.map((item) => {
          return (
            <tr key={item.id}>
              <td style={{ width: "125px" }}>
                <Moment format="l">{item.backupDateUtc}</Moment>
              </td>
              <td>{item.name}</td>
              <td>
                <span>
                  <>
                    <a
                      href="#"
                      onClick={() => {
                        setActiveItem(item);
                        setShowShareModal(true);
                      }}
                    >
                      <Translation translate="MYTD_shareBtnTxt" />
                    </a>
                    <a
                      href="#"
                      onClick={() => {
                        setActiveItem(item);
                        setShowDeleteModal(true);
                      }}
                    >
                      <Translation translate="MYTD_deleteLbl" />
                    </a>
                  </>
                </span>
              </td>
            </tr>
          );
        })}
      </Table>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        name={activeItem.name}
        translations={translations}
        fetchFn={fetchBooks}
        id={activeItem.id}
        type="books"
      />
      <ShareModal
        showModal={showShareModal}
        setShowModal={setShowShareModal}
        item={activeItem}
        translations={translations}
      />
    </div>
  );
};

export default Books;
