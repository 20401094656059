import React, { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";
import Translation from "@common/Translation/Index";
import { Link } from "react-router-dom";
import { redirectToLogout } from "@services/auth-service";

const ProfileDropdown = ({ user }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = (e) => {
    e.preventDefault();
    setDropdownOpen(!isDropdownOpen);
  };

  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const avatarStyle = {
    backgroundImage: `url(${user?.userDetail?.avatarUrl ? `/api/v1/${user.userDetail.avatarUrl}` : "/assets/images/avatar.jpg"})`,
  };

  return (
    user.userDetail && (
      <li
        className="dropdown hidden-xs"
        id="topbarProfileDropdown"
        key="topbarProfileDropdown"
        ref={dropdownRef}
      >
        <a
          href="#"
          title="Manage"
          className="dropdown-toggle profile-dropdown"
          onClick={toggleDropdown}
        >
          {user.userDetail.firstName} <span className="caret"></span>
          <span
            style={avatarStyle}
            className="img-circle img-circle-37 header-avatar"
          ></span>
        </a>
        <ul className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}>
          <li id="topbarProfile">
            <div className="pdd">
              <span className="caret pdd-caret"></span>
              <div className="pdd-overview clearfix">
                <div className="pull-left">
                  <div
                    style={avatarStyle}
                    className="img-circle img-circle-97 pdd-avatar"
                  ></div>
                </div>
                <div className="pdd-acct-info">
                  <h3>{`${user.userDetail.firstName} ${user.userDetail.lastName}`}</h3>
                  <p>
                    <span className="since-date">
                      <Translation translate="Since" />{" "}
                      {moment(user.userDetail.signUpDate).format("LL")}
                    </span>
                  </p>
                  <p>
                    <Link to="/myaccount" className="pdd-link">
                      <Translation translate="My_Account" />
                    </Link>
                    <a
                      href="#"
                      className="pdd-link"
                      onClick={(e) => {
                        e.preventDefault();
                        redirectToLogout();
                      }}
                    >
                      <Translation translate="Log_out" />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li>
    )
  );
};

export default ProfileDropdown;
